<template>
  <v-card
    flat
    class="pa-3 mt-1"
  >
    <v-card-title class="d-flex align-center justify-center py-0">
      <div
        class="d-flex align-center"
      >
        <v-img
          :src="require(`@/assets/images/logos/logo.png`)"
          max-width="300px"
          alt="logo"
          contain
          class="me-0 "
        ></v-img>
      </div>
    </v-card-title>
    <v-form class="multi-col-validation">
      <v-card-text class="pt-1">
        <p class="text-h6 text-center">Create a new Topup Spot</p>
        <v-row>
            <v-col cols="12" md="12" class="py-0 my-1">
              <v-text-field v-model="topup_station" ref="topup_station" outlined dense label="TopUp Station"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0 my-1">
              <v-select v-model="city" ref="city" :items="cities" outlined dense label="Select city"></v-select>
            </v-col>
            <v-col cols="12" md="6" class="py-0 my-1">
              <v-select v-model="country" ref="country" :items="countries" outlined dense label="Select a country"></v-select>
            </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="text-end">
        <spinner v-if="loading" class="text-end"></spinner>
        <v-btn 
          v-else
          color="primary"
          class="me-3 mt-3"
          @click="CreateZone()"
        >
          Create Zone
        </v-btn>

      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    return {
      loading: false,

      country: "",
      topup_station: "",
      city: "",

      mdiKeyOutline,
      mdiLockOpenOutline,
      mdiEyeOffOutline,
      mdiEyeOutline,
      cities: [
        'Yaounde',
        'Buea',
        'Bamenda',
        'Douala'
      ],
      countries: [
        'Cameroon',
        'Nigeria'
      ],
    }
  },
  
  mounted(){
      // executes these after the page has been mounted
      document.title = "Manager: Savings | Create Zone"
  },

  methods:{
    async CreateZone(){
      if(this.topup_station && this.topup_station.length < 4){
          this.$store.commit('setSnackBarMessage', "Please input topup station name")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.topup_station.focus()  // this causes a an auto fucos to the element
      }
      else if(this.city && this.city.length < 4){
              this.$store.commit('setSnackBarMessage', "Select city")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.city.focus()  // this causes a an auto fucos to the element
      }
      else if(this.country && this.country.length < 4){
          this.$store.commit('setSnackBarMessage', "Please select country")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.country.focus()  // this causes a an auto fucos to the element
      }
      else{
        this.loading = true
  
        let formData = new FormData()
        formData.append('country', this.country)
        formData.append('city', this.city)
        formData.append('topup_station', this.topup_station)

        await axios
            .post('/api/v1/manager/create/zone/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => {
                console.log(response.data)
                this.$store.commit('setSnackBarMessage', response.data[0].text)
                this.$store.commit('setSnackBarColor', response.data[0].color)
                this.$store.commit('activateSnackBar', true)
                this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                if (response.data[0].category === "success"){
                  this.$router.go()
                }
            })
            .catch(error => {
                if (error.response){
                    this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }else{
                    this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
            })

        this.loading = false
        }

    },
  },
}
</script>
